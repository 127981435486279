<template>
  <div class="home">
    <img src="/images/homepage-hero.png" alt="Players with team uniforms" />
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  background: url("/images/homepage-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 75px);
  overflow: hidden;
  img {
    display: block;
    max-width: 100%;
    margin: auto;
    margin-top: auto;
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    height: calc(100vh - 50px);
  }
}
</style>
